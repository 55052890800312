export function replacePlaceholders(obj, customer) {
  function getNestedValue(path, obj) {
    return path.split('.').reduce((value, key) => {
      return value !== null && value !== undefined ? value[key] : undefined;
    }, obj);
  }

  function replace(obj, customer) {
    if (typeof customer !== 'object' || customer === null) {
      // If customer is not an object or is null, return the original object
      return obj;
    }

    if (typeof obj === 'string') {
      return obj.replace(/\$\$(.*?)\$\$/g, (match, placeholder) => {
        const value = getNestedValue(placeholder, customer);
        return value !== undefined ? value : match;
      });
    } else if (Array.isArray(obj)) {
      return obj.map((item) => replace(item, customer));
    } else if (typeof obj === 'object' && obj !== null) {
      const newObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          newObj[key] = replace(obj[key], customer);
        }
      }
      return newObj;
    }
    return obj;
  }

  return replace(obj, customer);
}
