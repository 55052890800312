import './App.scss';
import React, { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import GlobalStateProvider from './components/layouts/GlobalStateProvider';
import LoadingSpinner from './components/atoms/LoadingSpinner';
import './helpers/cookie-consent';
import './helpers/cookie-consent-customs';
import EmailConfirm from './components/pages/EmailConfirm';
import PageLoader from './components/pages/PageLoader';
import { useHistoryNavigation } from './hooks/useHistoryNavigation';

function App() {
  // custom hook - handle browser navigation
  const handleBrowserNavigation = () => {
    window.location.reload();
  };
  useHistoryNavigation(handleBrowserNavigation);
  return (
    <BrowserRouter>
      <HelmetProvider>
        <GlobalStateProvider>
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route
                exact
                path='/error-404'
                element={<PageLoader pageKey={'not_found'} />}
              />
              <Route
                exact
                path='/error-500'
                element={<PageLoader pageKey={'error'} />}
              />
              <Route
                exact
                path=':id/error-expired'
                element={<PageLoader pageKey={'expired'} />}
              />
              <Route
                exact
                path=':id/error-404'
                element={<PageLoader pageKey={'not_found'} />}
              />
              <Route
                exact
                path=':id/error-500'
                element={<PageLoader pageKey={'error'} />}
              />
              <Route exact path=':id' element={<PageLoader route={'lp'} />} />
              <Route
                path=':id/confirm/:lastpage/:hash'
                element={<EmailConfirm />}
              />
              <Route
                path=':id/thank-you'
                element={<PageLoader pageKey={'end_pre_doi'} />}
              />
              <Route
                path=':id/confirmed-thank-you'
                element={<PageLoader pageKey={'end_after_doi'} />}
              />
              <Route
                path=':id/result-a'
                element={<PageLoader route={'result-a'} />}
              />
              <Route
                path=':id/result-b'
                element={<PageLoader route={'result-b'} />}
              />
              <Route
                path=':id/interaction'
                element={<PageLoader pageKey={'interaction'} />}
              />
              <Route
                path=':id/datenschutz'
                element={<PageLoader pageKey={'privacy'} />}
              />

              {/*old dev routes to be removed*/}
              <Route
                path=':id/interaction-ymo'
                element={<PageLoader pageKey={'interaction'} />}
              />
              {/*error 404 no route matched*/}
              <Route
                path=':id/*'
                element={<Navigate replace to='/error-404' />}
              />

              <Route
                path='/'
                element={
                  <>
                    <Navigate replace to='/home' />
                  </>
                }
              />
            </Routes>
          </Suspense>
        </GlobalStateProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
