import React from 'react';
import {Helmet} from 'react-helmet-async';

const Section = React.lazy(() => import('../components/elements/Section'));
const DefaultLayout = React.lazy(() => import('../components/layouts/DefaultLayout'));
const ReadMoreLink = React.lazy(() => import('../components/atoms/ReadMoreLink'));
const Button = React.lazy(() => import('../components/atoms/Button'));
const ButtonLink = React.lazy(() => import('../components/atoms/ButtonLink'));
const ButtonScrollToSection = React.lazy(() => import('../components/atoms/ButtonScrollToSection'));
const MagicText = React.lazy(() => import('../components/atoms/MagicText'));
const InteractionDropdownOption = React.lazy(() => import('../components/atoms/InteractionDropdownOption'));
const List = React.lazy(() => import('../components/elements/List'));
const ListIcon = React.lazy(() => import('../components/elements/ListIcon'));
const ContentCompositionBig = React.lazy(() => import('../components/elements/ContentCompositionBig'),);
const ContentCompositionHighlight = React.lazy(() => import('../components/elements/ContentCompositionHighlight'),);
const ContentText = React.lazy(() => import('../components/modules/ContentText'));
const Seal = React.lazy(() => import('../components/atoms/Seal'));
const ContentOffer = React.lazy(() => import('../components/modules/ContentOffer'));
const ContentHeadlineBlock = React.lazy(() => import('../components/modules/ContentHeadlineBlock'));
const StageContent = React.lazy(() => import('../components/modules/StageContent'));
const ContactAppointment = React.lazy(() => import('../components/modules/ContactAppointment'));
const ContentCard = React.lazy(() => import('../components/modules/ContentCard'));
const Accordion = React.lazy(() => import('../components/elements/Accordion'));
const Card = React.lazy(() => import('../components/atoms/Card'));
const Footer = React.lazy(() => import('../components/modules/Footer'));
const ContentColorplane = React.lazy(() => import('../components/modules/ContentColorplane'));
const ContentComposition = React.lazy(() => import('../components/modules/ContentComposition'));
const ContentCarousel = React.lazy(() => import('../components/modules/ContentCarousel'));
const CarouselItem = React.lazy(() => import('../components/elements/CarouselItem'));
const ContentCutoutvisual = React.lazy(() => import('../components/modules/ContentCutoutvisual'));
const Interaction = React.lazy(() => import('../components/modules/Interaction'));
const InteractionDropdown = React.lazy(() => import('../components/elements/InteractionDropdown'));
const ResultStageTable = React.lazy(() => import('../components/modules/ResultStageTable'));
const ContentVideovisual = React.lazy(() => import('../components/modules/ContentVideovisual'));
const ContentList = React.lazy(() => import('../components/modules/ContentList'));
const ContentListIcon = React.lazy(() => import('../components/modules/ContentListIcon'));
const PopupStandard = React.lazy(() => import('../components/elements/PopupStandard'));

function getComponentByBlockName(block, global) {
	if (block && block.collection) {
		switch (block.collection) {
			case 'block_section':
				return (
						<Section
								colWidth={'col-12 col-xl-10'}
								colOffset={'offset-xl-1'}
								key={block.id}
								{...block.item}
						>
							{pageBuilder(block.item, global)}
						</Section>
				);
			case 'atom_read_more_link':
				return <ReadMoreLink key={block.id} {...block.item} />;
			case 'atom_button':
				return <Button key={block.id} {...block.item} />;
			case 'atom_button_link':
				return <ButtonLink key={block.id} {...block.item} />;
			case 'atom_button_scroll_to_section':
				return <ButtonScrollToSection key={block.id} {...block.item} />;
			case 'atom_seal':
				return <Seal key={block.id} {...block.item} />;
			case 'atom_card':
				return <Card key={block.id} {...block.item} />;
			case 'atom_interaction_dropdown_option':
				return <InteractionDropdownOption key={block.id} {...block.item} />;
			case 'atom_magic_text':
				return <MagicText key={block.id} {...block.item} />
			case 'element_list':
				return <List key={block.id} {...block.item} />;
			case 'element_list_icon':
				return <ListIcon key={block.id} {...block.item} />;
			case 'element_content_composition_big':
				return (
						<ContentCompositionBig key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</ContentCompositionBig>
				);
			case 'element_content_composition_highlight':
				return (
						<ContentCompositionHighlight key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</ContentCompositionHighlight>
				);
			case 'element_interaction_dropdown':
				return (
						<InteractionDropdown key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</InteractionDropdown>
				);
			case 'element_popup_standard':
				return (
						<PopupStandard key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</PopupStandard>
				);
			case 'block_content_text':
				return (
						<ContentText key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</ContentText>
				);
			case 'block_content_offer':
				return (
						<ContentOffer key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</ContentOffer>
				);
			case 'block_content_headline_block':
				return (
						<ContentHeadlineBlock key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</ContentHeadlineBlock>
				);
			case 'block_stage_content':
				return (
						<StageContent key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</StageContent>
				);

			case 'block_content_accordion':
				return (
						<Accordion key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</Accordion>
				);
			case 'block_content_card':
				return (
						<ContentCard key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</ContentCard>
				);
			case 'block_footer':
				return (
						<Footer key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</Footer>
				);
			case 'block_contact_appointment':
				return (
						<ContactAppointment key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</ContactAppointment>
				);
			case 'block_content_composition':
				return (
						<ContentComposition key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</ContentComposition>
				);
			case 'block_content_colorplane':
				return (
						<ContentColorplane key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</ContentColorplane>
				);

			case 'element_carousel_item':
				return (
						<CarouselItem key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</CarouselItem>
				);

			case 'block_content_carousel':
				return (
						<ContentCarousel key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</ContentCarousel>
				);
			case 'block_content_cutoutvisual':
				return (
						<ContentCutoutvisual key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</ContentCutoutvisual>
				);
			case 'block_result_stage_table':
				return (
						<ResultStageTable key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</ResultStageTable>
				);
			case 'block_interaction':
				return (
						<Interaction key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</Interaction>
				);
			case 'block_content_videovisual':
				return (
						<ContentVideovisual key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</ContentVideovisual>
				);
			case 'block_content_list':
				return (
						<ContentList key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</ContentList>
				);
			case 'block_content_list_icon':
				return (
						<ContentListIcon key={block.id} {...block.item}>
							{pageBuilder(block.item)}
						</ContentListIcon>
				);
			default:
				return <p key={'not-found'}>Component {block.collection} not found</p>;
		}
	}
}

function pageBuilder(page, global, useDefaultLayout = false) {

	if (page && page.blocks) {
		if (useDefaultLayout) {
			return (
					<DefaultLayout
							hasContactBar={page.hasContactBar}
							isSubPage={page.isSubPage}
							globalData={global}
              pageSlug={page.slug}
					>
						<Helmet>
							{/* <title>{page.title}</title> */}
							<meta name='description' content={page.description}/>
						</Helmet>
						{page.blocks.map((block) => getComponentByBlockName(block, global))}
					</DefaultLayout>
			);
		} else {
			return page.blocks.map((block) => getComponentByBlockName(block, global));
		}
	}
}

export default pageBuilder;
