import { useEffect } from 'react';

/**
 * Custom hook which synchronizes the pages with the browser's navigation history via the popstate event.
 * This ensures a seamless user experience during navigation within these pages.
 *  @param methodToExecute - method which should be executed when the `popstate` event occurs.
 */
export function useHistoryNavigation(methodToExecute) {
  useEffect(() => {
    window.addEventListener('popstate', methodToExecute);
    return () => {
      window.removeEventListener('popstate', methodToExecute);
    };
  }, [methodToExecute]);
}
