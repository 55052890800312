import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {emailConfirmDOI} from '../../helpers/ergo';

function EmailConfirm() {
  const {id, lastpage, hash} = useParams();
  const navigate = useNavigate();
  // const [globalState, setGlobalState] = useGlobalState();

  useEffect(() => {
    // Confirm post to Ergo Backend
    // initErgoDataObject(id).then((campaignInfo) => {
    //   setGlobalState({
    //     ...globalState,
    //     campaignInfo: campaignInfo,
    //   });
    // });

    // redirect to result page needed after doi "result-nach-doi"
    try {
      const response = emailConfirmDOI(id, hash, lastpage);
      response.then(res => {
        if (res.data.success) {
          // TODO get actual dankeseite for user, AKA only one
          navigate('/' + id + '/confirmed-thank-you'); // end doi hardcoded
        }
      })
    } catch (e) {
      console.log('email confirm error: ', e);
      navigate('/' + id);
    }
  }, []);

  return (
    <div>Please wait</div>
  );
}

export default EmailConfirm;
