import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { initErgoDataObject, resetDummyUser } from '../../helpers/ergo';
import { useGlobalState } from '../layouts/GlobalStateProvider';
import LoadingSpinner from '../atoms/LoadingSpinner';
import { client } from '../../helpers/directus';
import { readItems } from '@directus/sdk/rest';
import { replacePlaceholders } from '../../helpers/placeholderReplacer';
import pageBuilder from '../../helpers/blockDispenser';
import { getStringAfterLastSlash, isChromium } from '../../helpers/navigation';

function PageLoader({ route, pageKey }) {
  const { id } = useParams();
  const [pageId, setPageId] = useState(null);
  const [page, setPage] = useState(null);
  const [globalState, setGlobalState] = useGlobalState();
  const navigate = useNavigate();

  const navigateToPage = (page, id) => {
    navigate(`${id ? `/${id}/` : '/'}${page}`);
    if (isChromium()) navigate(0);
  };

  useEffect(() => {
    let isMounted = true;
    let potentialTargetRoute = route;

    if (isMounted) {
      if (route) {
        potentialTargetRoute = route;
      }

      if (!pageId) {
        initErgoDataObject(id).then((campaignInfo) => {
          if (campaignInfo.user) {
            // should be, otherwise 404 would redirect to /error-404
            let potentialPageKey = '';

            // Preparation for the check of the expiration date
            let expiryDate = null;
            const parts = campaignInfo?.user?.end_date?.split('.');
            const today = new Date().toISOString();
            if (parts && parts.length > 1) {
              const day = parseInt(parts[0], 10);
              const month = parseInt(parts[1], 10) - 1;
              const year = parseInt(parts[2], 10);
              expiryDate = new Date(year, month, day).toISOString();
            }

            const isDummy = campaignInfo?.user?.is_dummy;
            const currentlyVisitedPage = `/${getStringAfterLastSlash(window.location.pathname) ?? ''}`;
            const isOnLandingPage =
              currentlyVisitedPage === `/${id}` || currentlyVisitedPage === '/';
            const isExpired =
              id &&
              expiryDate &&
              today > expiryDate &&
              !window.location.href.endsWith('error-expired');

            if (!isDummy) {
              if (isExpired) {
                navigateToPage('error-expired', id);
                if (!isChromium()) setTimeout(() => navigate(0), 300);
              }
            } else if (isOnLandingPage) {
              resetDummyUser(id);
            }

            try {
              if (route) {
                // TODO: Remove this if a solution for targets like "all" was found
                if (campaignInfo.user?.zg) {
                  potentialTargetRoute += '_';
                  potentialTargetRoute += campaignInfo.user?.zg.toLowerCase();
                } else {
                  const target = ['young', 'medium', 'old'].includes(
                    campaignInfo.user?.target
                  )
                    ? campaignInfo.user?.target
                    : 'young';
                  potentialTargetRoute += '_';
                  potentialTargetRoute += target;
                }

                potentialPageKey = potentialTargetRoute.replace('-', '_');
              } else if (pageKey) {
                potentialPageKey = pageKey;
              }
            } catch (error) {
              console.log(error);
            }

            const pageId = campaignInfo.campaignInfo[potentialPageKey];

            loadPageById(pageId, campaignInfo.user);

            setGlobalState({
              ...globalState,
              campaignInfo: campaignInfo,
              currentPage: route ?? pageKey,
            });
          }
        });
      }
    }

    return () => {
      isMounted = false;
      setPageId(null);
    };
  }, []);

  const loadPageById = useCallback((pageId, passedUser) => {
    const filterBy = { id: { _eq: pageId } };
    const user = passedUser ?? globalState.campaignInfo.user;

    client
      .request(
        readItems('pages', {
          filter: {
            ...filterBy,
          },
          fields: [
            '*',
            {
              blocks: [
                '*',
                {
                  item: [
                    '*',
                    {
                      blocks: [
                        '*',
                        {
                          item: [
                            '*',
                            {
                              blocks: [
                                '*',
                                {
                                  item: [
                                    '*',
                                    {
                                      blocks: [
                                        '*',
                                        {
                                          item: [
                                            '*',
                                            {
                                              blocks: [
                                                '*',
                                                {
                                                  item: [
                                                    '*',
                                                    {
                                                      blocks: [
                                                        '*',
                                                        {
                                                          item: [
                                                            '*',
                                                            {
                                                              blocks: [
                                                                '*',
                                                                {
                                                                  item: [
                                                                    '*',
                                                                    {
                                                                      blocks: [
                                                                        '*',
                                                                        {
                                                                          item: [
                                                                            '*',
                                                                          ],
                                                                        },
                                                                      ],
                                                                    },
                                                                  ],
                                                                },
                                                              ],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          limit: 1,
        })
      )
      .then((res) => {
        const filledObject = { ...replacePlaceholders(res[0], user) };
        const builtPage = pageBuilder(filledObject, {}, true);
        setPage(builtPage);
      })
      .catch((_) => {
        location.href = '/error-500';
      });
  }, []);

  if (page) {
    return <>{page}</>;
  } else {
    return <LoadingSpinner />;
  }
}

export default PageLoader;
