//Listener is applied to document to catch all click events and filter for the ones on cookie-details. since they are dynamic
(() => {
  function handleDetailsClick(e) {
    let target = e.target;
    target.classList.toggle('active');
    const aside = target.querySelector('aside');
    if (aside) {
      if (aside.style.display === 'none' || aside.style.display === '') {
        aside.style.display = 'block';
      } else {
        aside.style.display = 'none';
      }
    }

    const cookieConsentPreferencesDialog = document.querySelector('.cookie-consent-preferences-dialog');
    const cookieConsentPreferencesOverlay = document.querySelector('.cookie-consent-preferences-overlay');
    const windowHeight = window.innerHeight;
    const cookieNoticeHeight = cookieConsentPreferencesDialog.offsetHeight;
    if (windowHeight < cookieNoticeHeight) {
      cookieConsentPreferencesOverlay.style.overflowY = 'scroll';
      cookieConsentPreferencesOverlay.style.display = 'block';
    } else {
      cookieConsentPreferencesOverlay.style.overflowY = 'auto';
      cookieConsentPreferencesOverlay.style.display = 'flex';
    }
  }

  document.body.addEventListener('click', function (event) {
    if (event.target && event.target.classList.contains('cookie-details')) {
      handleDetailsClick(event);
    }
  });

  document.body.addEventListener('click', function (event) {
    if (event.target && event.target.classList.contains('cc_cp_head_title')) {
      const cookieConsentPreferencesOverlay = document.querySelector('.cookie-consent-preferences-overlay');
      cookieConsentPreferencesOverlay.classList.remove('visible');
    }
  });

})();
