const css = `html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.main-container {
  overflow: hidden;
}

.missing-component {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 20px;
  color: rebeccapurple;
}

.cookie-consent-preferences-overlay.cc_css_reboot.visible {
  display: block;
}

.cookie-consent-preferences-overlay.cc_css_reboot:not(.visible) {
  display: none;
  pointer-events: none;
}

`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
